import React from "react";

export const Context = React.createContext({
  project: 0,
  area_role_idx: 0,
  auto_flag: true,
  info: {},
  user_info: {},
  msgHeaderCode: "0000",
  refresh_flag: new Date().getTime(),
  selectProject: () => {},
  setUserInfo: () => {},
  selectAuto: () => {},
  changeAuth: () => {},
  refreshHeader: () => {},
});

class IntlProviderWrapper extends React.Component {
  state = {
    project: 0,
    area_role_idx: 0,
    auto_flag: true,
    info: {},
    user_info: {},
    msgHeaderCode: "0000",
    refresh_flag: new Date().getTime(),
    selectProject: () => {},
    setUserInfo: () => {},
    selectAuto: () => {},
    changeAuth: () => {},
    refreshHeader: () => {},
  };
  setUserInfo = (user_info) => {
    this.setState({
      user_info: user_info,
    });
  };

  render() {
    const { children } = this.props;
    // const { project } = this.state;
    return (
      <Context.Provider
        value={{
          state: this.state,
          selectProject: (idx, info, area_role_idx) => {
            this.setState({
              project: idx,
              info: info,
              area_role_idx: area_role_idx,
            });
          },
          selectAuto: (flag) => {
            // console.log("selectAuto", flag);
            this.setState({
              auto_flag: flag,
            });
          },
          setUserInfo: (user_info) => {
            // console.log("selectAuto", flag);
            this.setState({
              user_info: user_info,
            });
          },
          changeAuth: (auth) => {
            // console.log('changeAuth', auth);
            this.setState({
              msgHeaderCode: auth,
            });
          },
          refreshHeader: () => {
            this.setState({
              refresh_flag: new Date().getTime(),
            });
          },
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as projectContext };
