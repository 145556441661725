import React from "react";
import { Link, withRouter } from "react-router-dom";
import { projectContext } from "../../../utility/context/projectSelect";

// import "../../../assets/css/base.css";
import { ReactComponent as IcoInfo } from "../../../assets/icons/sidebar/sidebar_info.svg";
import { ReactComponent as IcoDidSetting } from "../../../assets/icons/menu/did_setting.svg";

class Sidebar extends React.Component {
  static contextType = projectContext;
  logout = () => {
    const contextValue = this.context;
    if (window.confirm("로그아웃 하시겠습니까?")) {
      localStorage.removeItem("uToken");
      contextValue.selectProject(0, "", 0);
      this.props.history.push("/login");
    }
  };
  render() {
    const contextValue = this.context;
    if (window.location.pathname === "/login") return null;
    // console.log('contextValue', contextValue.state.area_role_idx);
    return (
      <React.Fragment>
        <menu id="menu">
          {/* <!-- 홈 --> */}
          <Link to="/">
            <button className="pic-home" data-title="home"></button>
          </Link>
          {/* <!-- 암릴 이력 --> */}
          {contextValue.state.area_role_idx < 4 ? (
            <Link to="/alert">
              <button className="pic-alram" data-title="Alram"></button>
            </Link>
          ) : null}

          {/* <!-- 차트 --> */}
          {contextValue.state.area_role_idx < 4 ? (
            <Link to="/graph">
              <button className="pic-chart" data-title="Chart"></button>
            </Link>
          ) : null}

          {/* <!-- 데이터 --> */}
          {/* <button className="pic-data" data-title="Data" ></button> */}

          {/* <!-- 프로젝트 설정 --> */}
          {contextValue.state.area_role_idx < 4 ? (
            <Link to="/area">
              <button className="pic-admin" data-title="Setting"></button>
            </Link>
          ) : null}

          {/* <!-- 스타일 편집 --> */}
          {/*<Link to="/widget">*/}
          {/*    <button className="pic-style" data-title="Style"></button>*/}
          {/*</Link>*/}

          {/* <!-- 스케쥴 관리 --> */}
          {/*<Link to="/schedule">*/}
          {/*    <button className="pic-recode" data-title="Recode" ></button>*/}
          {/*</Link>*/}

          {/* <!-- 룰 관리 --> */}
          {contextValue.state.area_role_idx < 4 ? (
            <Link to="/rule">
              <button className="pic-recode" data-title="Recode"></button>
            </Link>
          ) : null}

          {/*{contextValue.state.area_role_idx < 4 ? <Link to="/did/setting">*/}
          {/*    /!*<button className="pic-70"></button>*!/*/}
          {/*    <button className="navbar_custom">*/}
          {/*        <IcoDid width="30" height="30" fill="#42b4f4" />*/}
          {/*    </button>*/}
          {/*</Link> : null}*/}

          {contextValue.state.area_role_idx < 4 ? (
            <Link to="/did/setting">
              {/*<button className="pic-70"></button>*/}
              <button className="navbar_custom">
                <IcoDidSetting width="30" height="30" fill="#42b4f4" />
              </button>
            </Link>
          ) : null}

          {/* 스마트팜 소개 */}
          {/*<button className="navbar_custom" onClick={e => window.open('/static/info', 'info', 'width=620,height=1200')}>*/}
          {/*    <IcoInfo width="24" height="24" />*/}
          {/*</button>*/}

          {/* <!-- 로그아웃 --> */}
          <button className="pic-logout" data-title="Logout" onClick={(e) => this.logout()}></button>
        </menu>
      </React.Fragment>
    );
  }
}

export default withRouter(Sidebar);
