require("dotenv").config();

const APIDV_URL = process.env.REACT_APP_API_HOST;

const REST_URL = APIDV_URL;

export const OK = "0000";
export const OK_NODATA = "0001";
export const BAD_REQUEST = "1001";
export const UNAUTHORIZED_REQUEST = "1002";
export const INTERNAL_SERVER_ERROR = "1003";
export const NOT_FOUND = "1004";
export const OUT_OF_LENGTH_REQUEST = "1005";
export const INVALID_TOKEN = "2001";
export const INVALID_ID = "2002";
export const INVALID_PASSWORD = "2003";
export const LOGIN_LOCK = "2005";
export const DUPLICATE_NAME_AREA = "3001";
export const DUPLICATE_NAME_DEVICE = "3002";
export const DUPLICATE_NAME_ROLE = "3005";
export const DUPLICATE_NAME_MENU = "3006";
export const DUPLICATE_NAME_USER = "3007";
export const RULE_IS_NOT_EXIST = "4001";
export const ALREADY_RUN_AUTOMODE = "4002";
export const ApiService = {
  api_login,
  api_valid,

  api_all_area_list,
  api_area_list,
  api_area_detail,
  api_area_add,
  api_area_add_excel,
  api_area_edit,
  api_area_delete,

  api_board_reset,
  api_board_update,
  api_board_list,
  api_board_detail,
  api_board_add,
  api_board_add_excel,
  api_board_edit,
  api_board_delete,
  api_board_release_list,

  api_device_area,
  api_device_list,
  api_device_detail,
  api_device_add,
  api_device_add_excel,
  api_device_edit,
  api_device_delete,

  api_search_graph,

  api_code_boardType,
  api_code_deviceModel,
  api_code_deviceModel_detail,
  api_code_deviceType,
  api_code_deviceCategory,
  api_code_deviceVendor,

  api_dashboard_list,
  api_dashboard_status,
  api_dashboard_mode_change,
  api_dashboard_work_change,
  api_dashboard_notification,
  api_dashboard_devicelist_mode_change,

  api_notification_list,
  api_notification_confirm,
  api_notification_confirm_all,

  api_schedule_list,
  api_schedule_add,
  api_schedule_edit,
  api_schedule_delete,

  api_widget_upsertPage,
  api_widget_save,
  api_widget_deletePage,
  api_widget_template_list,
  api_widget_shiftPage,

  api_did_info,
  api_did_page,
  api_did_status,
  api_did_graph,
  api_did_admin_upload,
  api_did_admin_save,

  api_rule_device_list,
  api_rule_device_save,
  api_rule_preset_list,
  api_rule_preset_add,
  api_rule_preset_delete,
  api_rule_device_multi_save,
  api_rule_alarm_bulk_save,

  api_rule_group_list,
  api_rule_group_export_json,
  api_rule_group_add,
  api_rule_group_save,
  api_rule_group_use,
  api_rule_group_delete,
  api_rule_group_detail,
  api_rule_group_copy,

  api_offline_rule_device_save,
  api_offline_rule_device_list,

  api_role_list,
  api_role_add,
  api_role_edit,
  api_role_delete,

  api_menu_sort,
  api_menu_list,
  api_menu_add,
  api_menu_edit,
  api_menu_delete,

  api_role_menu_list,
  api_role_menu_check,
  api_role_menu_edit,

  api_user_group_list,
  api_user_group_area_list,
  api_user_group_add,
  api_user_group_edit,
  api_user_group_delete,
  api_user_group_detail,
  api_user_group_file_upload,

  api_area_role_list,
  api_user_list,
  api_user_not_exist_group_list,
  api_user_add,
  api_user_edit,
  api_user_delete,

  api_smart_gb_list,
  api_smart_gb_upsert,
  api_smart_gb_delete,

  api_smart_gb_setting_detail,
  api_smart_gb_setting_upsert,

  api_notice_list,
  api_notice_detail,
  api_notice_manage_list,
  api_notice_manage_detail,
  api_notice_manage_add,
  api_notice_manage_edit,
  api_notice_file_upload,
  api_notice_file_delete,
  api_notice_popup_list,
  api_qna_list,
  api_qna_detail,
  api_qna_add,
  api_qna_edit,
  api_qna_delete,
  api_qna_file_upload,
  api_qna_file_delete,
  api_qna_comment_add,
  api_qna_comment_edit,
  api_qna_comment_delete,
};

////////////////////////////////////////////////////////////////////////////////////
//
// REQUEST
//
////////////////////////////////////////////////////////////////////////////////////
//  apiCommon
function api_common(url, data, needAuth) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  if (needAuth) {
    requestOptions.headers.authorization = "Bearer " + window.localStorage.getItem("uToken");
  }
  if (data) {
    requestOptions.body = JSON.stringify(data);
  }
  const baseUrl = REST_URL + `${url}`;

  return fetch(baseUrl, requestOptions).then(handleResponse);
}

// memberApiService
function api_login(userId, userPass, uuid, device_name) {
  return api_common(
    "/user/login",
    {
      user_id: userId,
      user_password: userPass,
      login_device: {
        device_uuid: uuid,
        device_name: device_name,
        device_type: "WEB",
        device_os: "3.1.10",
        push_token: "",
      },
    },
    false
  );
}
function api_valid() {
  return api_common("/user/valid", null, true);
}

function api_all_area_list() {
  return api_common("/area/all-list", null, true);
}
function api_area_list() {
  return api_common("/area/list", null, true);
}
function api_area_detail(area_idx) {
  return api_common("/area/detail", { area_idx: area_idx }, true);
}
function api_area_add(area) {
  return api_common("/area/add", area, true);
}
function api_area_add_excel(formData) {
  // return api_common("/area/add/excel", formData, true);
  const requestOptions = {
    method: "POST",
    headers: {
      // "content-type": "multipart/form-data",
      authorization: "Bearer " + window.localStorage.getItem("uToken"),
    },
    body: formData,
  };
  var url = REST_URL + "/area/add/excel";
  return fetch(url, requestOptions).then(handleResponse);
}
function api_area_edit(area) {
  return api_common("/area/edit", area, true);
}
function api_area_delete(area_idx) {
  return api_common("/area/delete", { area_idx: area_idx }, true);
}

function api_board_reset(board_serial, area_idx) {
  return api_common("/board/reset", { board_serial: board_serial, area_idx: area_idx }, true);
}
function api_board_update(board_serial, area_idx) {
  return api_common("/board/update", { board_serial: board_serial, area_idx: area_idx }, true);
}
function api_board_list(area_idx) {
  return api_common("/board/list", { area_idx: area_idx }, true);
}
function api_board_detail(device_serial) {
  return api_common("/board/detail", { board_serial: device_serial }, true);
}
function api_board_add(board) {
  return api_common("/board/add", board, true);
}
function api_board_add_excel(formData) {
  // return api_common("/board/add/excel", formData, true);
  const requestOptions = {
    method: "POST",
    headers: {
      // "content-type": "multipart/form-data",
      authorization: "Bearer " + window.localStorage.getItem("uToken"),
    },
    body: formData,
  };
  var url = REST_URL + "/board/add/excel";
  return fetch(url, requestOptions).then(handleResponse);
}
function api_board_edit(board) {
  return api_common("/board/edit", board, true);
}
function api_board_delete(area_idx, board_serial) {
  return api_common("/board/delete", { area_idx: area_idx, board_serial: board_serial }, true);
}
function api_board_release_list() {
  return api_common("/board/release/list", null,true);
}

function api_device_area(area_idx) {
  return api_common("/device/area", { area_idx: area_idx }, true);
}
function api_device_list(board_serial) {
  return api_common("/device/list", { board_serial: board_serial }, true);
}
function api_device_detail(device_serial) {
  return api_common("/device/detail", { device_serial: device_serial }, true);
}
function api_device_add(device) {
  return api_common("/device/add", device, true);
}
function api_device_add_excel(formData) {
  // return api_common("/device/add/excel", formData, true);
  const requestOptions = {
    method: "POST",
    headers: {
      // "content-type": "multipart/form-data",
      authorization: "Bearer " + window.localStorage.getItem("uToken"),
    },
    body: formData,
  };
  var url = REST_URL + "/device/add/excel";
  return fetch(url, requestOptions).then(handleResponse);
}
function api_device_edit(device) {
  return api_common("/device/edit", device, true);
}
function api_device_delete(board_serial, device_serial, area_idx) {
  return api_common("/device/delete", { board_serial: board_serial, device_serial: device_serial, area_idx: area_idx }, true);
}

function api_search_graph(device_serial_list, start_at, end_at, interval_type, interval, aggregation_type) {
  let request = {
    device_serial_list: device_serial_list,
    start_at: start_at,
    end_at: end_at,
    interval_type: interval_type,
    interval: interval,
    aggregation_type: aggregation_type,
  }
  return api_common("/graph/search", request, true);
}

function api_code_boardType() {
  return api_common("/code/board-type", null, true);
}

function api_code_deviceModel(device_vendor_idx) {
  return api_common("/code/device-model", {device_vendor_idx: device_vendor_idx}, true);
}

function api_code_deviceModel_detail(device_model_idx) {
  return api_common("/code/device-model/detail", {device_model_idx: device_model_idx}, true);
}
function api_code_deviceType() {
  return api_common("/code/device-type", null, true);
  // body: JSON.stringify(user),
}
function api_code_deviceCategory() {
  return api_common("/code/device-category", null, true);
  // body: JSON.stringify(user),
}

function api_code_deviceVendor() {
  return api_common("/code/device-vendor", null, true);
}

function api_dashboard_list(area_idx, page) {
  return api_common("/dashboard/list", { area_idx: area_idx, page: page }, true);
}
function api_dashboard_status(area_idx, device_serial_list) {
  return api_common("/dashboard/status", { area_idx: area_idx, device_serial_list: device_serial_list }, true);
}
function api_dashboard_mode_change(device_serial, auto_yn, area_idx) {
  return api_common("/dashboard/mode/change", { device_serial: device_serial, auto_yn: auto_yn, area_idx: area_idx }, true);
}
function api_dashboard_work_change(device_serial, action_index, area_idx) {
  return api_common("/dashboard/work/change", { device_serial: device_serial, action_index: action_index, area_idx: area_idx }, true);
}

function api_dashboard_notification(area_idx, critical_level) {
  return api_common(
    "/dashboard/notification",
    {
      area_idx: area_idx,
      critical_level: critical_level,
    },
    true
  );
}

function api_dashboard_devicelist_mode_change(area_idx, device_serial_list, auto_yn) {
  return api_common (
    "/dashboard/device-list/mode/change",
    {
      area_idx: area_idx,
      device_serial_list: device_serial_list,
      auto_yn: auto_yn,
    },
    true
  );
}

function api_notification_list(area_idx, start_at, end_at, page, limit, device_name, device_category_idx, device_serial, critical_level) {
  return api_common(
    "/notification/list",
    {
      area_idx_list: area_idx,
      start_at: start_at,
      end_at: end_at,
      page: page,
      limit: limit,
      device_name: device_name,
      device_category_idx: device_category_idx,
      device_serial: device_serial,
      critical_level: critical_level,
    },
    true
  );
}

function api_notification_confirm(area_idx, notification_id, confirm_flag) {
  return api_common(
    "/notification/confirm",
    {
      area_idx: area_idx,
      notification_id: notification_id,
      confirm_flag: confirm_flag,
    },
    true
  );
}

function api_notification_confirm_all(area_idx) {
  return api_common("/notification/confirm-all", { area_idx: area_idx }, true);
}

function api_schedule_list(area_idx) {
  return api_common("/schedule/list", { area_idx: area_idx }, true);
}
function api_schedule_add(schedule) {
  return api_common("/schedule/add", schedule, true);
}
function api_schedule_edit(schedule) {
  return api_common("/schedule/edit", schedule, true);
}
function api_schedule_delete(rule_id, area_idx) {
  return api_common("/schedule/delete", { rule_id: rule_id, area_idx: area_idx }, true);
}

function api_widget_upsertPage(widget_data) {
  return api_common("/widget/upsert-page", widget_data, true);
}
function api_widget_save(widget_data) {
  return api_common("/widget/save", widget_data, true);
}
function api_widget_deletePage(area_idx, dashboard_page) {
  return api_common("/widget/delete-page", { area_idx: area_idx, dashboard_page: dashboard_page }, true);
}
function api_widget_template_list() {
  return api_common("/widget/template/list", {}, true);
}
function api_widget_shiftPage(area_idx, before_dashboard_page, after_dashboard_page) {
  return api_common("/widget/shift-page", { area_idx: area_idx, before_dashboard_page: before_dashboard_page, after_dashboard_page: after_dashboard_page }, true);
}

function api_did_info(area_idx) {
  return api_common("/did/info", { area_idx: area_idx }, true);
}
function api_did_page(area_idx, page) {
  return api_common("/did/page", { area_idx: area_idx, page: page }, true);
}
function api_did_status(area_idx, device_serial_list) {
  return api_common("/did/status", { area_idx: area_idx, device_serial_list: device_serial_list }, true);
}
function api_did_graph(device_serial) {
  return api_common("/did/graph", { device_serial: device_serial }, true);
}
function api_did_admin_upload(file) {
  // return api_common("/did/admin/upload", file, true);
  const requestOptions = {
    method: "POST",
    headers: {
      // "content-type": "multipart/form-data",
      authorization: "Bearer " + window.localStorage.getItem("uToken"),
    },
    body: file,
  };
  var url = REST_URL + "/did/admin/upload";
  return fetch(url, requestOptions).then(handleResponse);
}
function api_did_admin_save(area_idx, did_info) {
  return api_common("/did/admin/save", { area_idx: area_idx, did_info: did_info }, true);
}

function api_rule_group_list(area_idx) {
  return api_common("/rule/group/list", { area_idx: area_idx }, true);
}

function api_rule_group_export_json(rule_group_id) {
  return api_common("/rule/group/export/json", { rule_group_id: rule_group_id }, true);
}

function api_rule_group_add(area_idx, rule_group) {
  return api_common(
    "/rule/group/add",
    {
      area_idx: area_idx,
      rule_group_name: rule_group.rule_group_name,
      rule_group_type: rule_group.rule_group_type,
      pattern_type: rule_group.pattern_type,
      start_at: rule_group.start_at,
      repeat_yn: rule_group.repeat_yn,
      pattern_data_list: rule_group.pattern_data_list,
      air_conditioner_config_data: rule_group.air_conditioner_config_data,
    },
    true
  );
}
function api_rule_group_save(area_idx, rule_group) {
  return api_common(
    "/rule/group/edit",
    {
      area_idx: area_idx,
      rule_group_id: rule_group.rule_group_id,
      rule_group_name: rule_group.rule_group_name,
      rule_group_type: rule_group.rule_group_type,
      pattern_type: rule_group.pattern_type,
      start_at: rule_group.start_at,
      repeat_yn: rule_group.repeat_yn,
      pattern_data_list: rule_group.pattern_data_list,
      air_conditioner_config_data: rule_group.air_conditioner_config_data,
    },
    true
  );
}
function api_rule_group_use(area_idx, rule_group_id, use_yn) {
  return api_common("/rule/group/work/change", { area_idx: area_idx, rule_group_id: rule_group_id, use_yn: use_yn }, true);
}
function api_rule_group_delete(area_idx, rule_group_id) {
  return api_common("/rule/group/delete", { area_idx: area_idx, rule_group_id: rule_group_id }, true);
}
function api_rule_group_detail(area_idx, rule_group_id) {
  return api_common("/rule/group/detail", { area_idx: area_idx, rule_group_id: rule_group_id }, true);
}
function api_rule_group_copy(area_idx, rule_group_id, paste_rule_group_name) {
  return api_common("/rule/group/copy", { area_idx: area_idx, rule_group_id: rule_group_id, paste_rule_group_name: paste_rule_group_name }, true);
}
function api_rule_device_list(area_idx, rule_group_id) {
  return api_common("/rule/device/list", { area_idx: area_idx, rule_group_id: rule_group_id }, true);
}
function api_rule_device_save(area_idx, device_serial, rule_type, rule_list, rule_group_id) {
  return api_common(
    "/rule/device/save",
    {
      area_idx: area_idx,
      device_serial: device_serial,
      rule_type: rule_type,
      rule_list: rule_list,
      rule_group_id: rule_group_id,
    },
    true
  );
}
function api_rule_device_multi_save(area_idx, device_rule_list, rule_type, rule_group_id) {
  return api_common(
    "/rule/device/multi/save",
    {
      area_idx: area_idx,
      device_rule_list: device_rule_list,
      rule_type: rule_type,
      rule_group_id: rule_group_id,
    },
    true
  );
}

function api_rule_alarm_bulk_save(area_idx_list, device_category_idx, compare, value, alarm_title, critical_level, alarm_message) {
  return api_common(
    "/rule/alarm/bulk-save",
    {
      area_idx_list: area_idx_list,
      device_category_idx: device_category_idx,
      compare: compare,
      value: value,
      alarm_title: alarm_title,
      critical_level: critical_level,
      alarm_message: alarm_message,
    },
    true
  );
}

function api_rule_preset_list() {
  return api_common("/rule/preset/list", {}, true);
}
function api_rule_preset_add(preset_name, preset_data) {
  return api_common("/rule/preset/add", { rule_preset_name: preset_name, rule_preset_data: preset_data }, true);
}
function api_rule_preset_delete(rule_preset_id) {
  return api_common("/rule/preset/delete", { rule_preset_id: rule_preset_id }, true);
}

function api_offline_rule_device_save(area_idx, device_serial, rule_list) {
  return api_common("/offline-rule/device/save", {
      area_idx: area_idx,
      device_serial: device_serial,
      rule_list: rule_list,
    }, true);
}
function api_offline_rule_device_list(area_idx, board_serial) {
  return api_common("/offline-rule/device/list", { area_idx: area_idx, board_serial: board_serial, }, true);
}

function api_role_list() {
  return api_common("/role/list", {}, true);
}
function api_role_add(role) {
  return api_common("/role/add", role, true);
}
function api_role_edit(role) {
  return api_common("/role/edit", role, true);
}
function api_role_delete(role_idx) {
  return api_common("/role/delete", { role_idx: role_idx }, true);
}
function api_menu_sort(menu_list) {
  return api_common("/menu/sort", { menu_list: menu_list }, true);
}
function api_menu_list(role_idx) {
  return api_common("/menu/list", { role_idx: role_idx }, true);
}
function api_menu_add(menu) {
  return api_common("/menu/add", menu, true);
}
function api_menu_edit(menu) {
  return api_common("/menu/edit", menu, true);
}
function api_menu_delete(menu_idx) {
  return api_common("/menu/delete", { menu_idx: menu_idx }, true);
}
function api_role_menu_list(role_idx) {
  return api_common("/roleMenu/list", { role_idx: role_idx }, true);
}
function api_role_menu_check(menu_idx) {
  return api_common("/roleMenu/check", { menu_idx: menu_idx }, true);
}
function api_role_menu_edit(role_idx, menu_id_list) {
  return api_common("/roleMenu/edit", { role_idx: role_idx, menu_id_list: menu_id_list }, true);
}
function api_user_group_list() {
  return api_common("/userGroup/list", {}, true);
}
function api_user_group_area_list(user_group_idx) {
  return api_common("/userGroup/areaList", { user_group_idx: user_group_idx }, true);
}
function api_user_group_add(user_group) {
  return api_common("/userGroup/add", user_group, true);
}
function api_user_group_edit(user_group) {
  return api_common("/userGroup/edit", user_group, true);
}
function api_user_group_delete(user_group_idx) {
  return api_common("/userGroup/delete", { user_group_idx: user_group_idx }, true);
}
function api_user_group_detail(user_group_idx) {
  return api_common("/userGroup/detail", { user_group_idx: user_group_idx }, true);
}
function api_user_group_file_upload(file) {
  const requestOptions = {
    method: "POST",
    headers: {
      authorization: "Bearer " + window.localStorage.getItem("uToken"),
      // "content-type": "multipart/form-data",
    },
    body: file,
  };
  const url = REST_URL + "/userGroup/file/upload";
  return fetch(url, requestOptions).then(handleResponse);
}
function api_area_role_list() {
  return api_common("/areaRole/list", {}, true);
}
function api_user_list(user_group_idx) {
  return api_common("/user/list", { user_group_idx: user_group_idx }, true);
}
function api_user_not_exist_group_list() {
  return api_common("/user/notExistGroupList", {}, true);
}
function api_user_add(user) {
  return api_common("/user/add", user, true);
}
function api_user_edit(user) {
  return api_common("/user/edit", user, true);
}
function api_user_delete(user_idx) {
  return api_common("/user/delete", { user_idx: user_idx }, true);
}

function api_smart_gb_list(area_idx, page, limit) {
  return api_common(
    "/smart-gb/list",
    {
      area_idx: area_idx,
      page: page,
      limit: limit,
    },
    true
  );
}
function api_smart_gb_upsert(smartGb) {
  return api_common("/smart-gb/upsert", smartGb, true);
}
function api_smart_gb_delete(smartGb) {
  return api_common("/smart-gb/delete", smartGb, true);
}
function api_smart_gb_setting_detail(area_idx) {
  return api_common("/smart-gb/setting/detail", { area_idx: area_idx }, true);
}
function api_smart_gb_setting_upsert(smartGbSetting) {
  return api_common("/smart-gb/setting/upsert", smartGbSetting, true);
}
function api_notice_list(listData) {
  return api_common("/notice/list", listData, true);
}
function api_notice_detail(notice_idx) {
  return api_common("/notice/detail", {notice_idx: notice_idx}, true);
}
function api_notice_manage_list(listData) {
  return api_common("/notice/manage/list", listData, true);
}
function api_notice_manage_detail(notice_idx) {
  return api_common("/notice/manage/detail", {notice_idx: notice_idx}, true);
}
function api_notice_manage_add(noticeData) {
  return api_common("/notice/manage/add", noticeData, true);
}
function api_notice_manage_edit(noticeData) {
  return api_common("/notice/manage/edit", noticeData, true);
}
function api_notice_file_upload(file) {
  const requestOptions = {
    method: "POST",
    headers: {
      authorization: "Bearer " + window.localStorage.getItem("uToken"),
      // "content-type": "multipart/form-data",
    },
    body: file,
  };
  const url = REST_URL + "/notice/file/upload";
  return fetch(url, requestOptions).then(handleResponse);
}
function api_notice_file_delete(file_idx) {
  return api_common("/notice/file/delete", {file_idx: file_idx}, true);
}
function api_notice_popup_list() {
  return api_common("/notice/popup/list",{},true);
}
function api_qna_list(listData) {
  return api_common("/qna/list", listData, true);
}
function api_qna_detail(qnaData) {
  return api_common("/qna/detail", {post_idx: qnaData}, true);
}
function api_qna_add(qnaData) {
  return api_common("/qna/add", qnaData, true);
}
function api_qna_edit(qnaData) {
  return api_common("/qna/edit", qnaData, true);
}
function api_qna_delete(qnaData) {
  return api_common("/qna/delete", {post_idx: qnaData}, true);
}
function api_qna_file_upload(file) {
  const requestOptions = {
    method: "POST",
    headers: {
      authorization: "Bearer " + window.localStorage.getItem("uToken"),
    },
    body: file,
  };
  const url = REST_URL + "/qna/file/upload";
  return fetch(url, requestOptions).then(handleResponse);
}
function api_qna_file_delete(file_idx) {
  return api_common("/qna/file/delete", {file_idx: file_idx}, true);
}
function api_qna_comment_add(qnaData) {
  return api_common("/qna/comment/add", qnaData, true);
}
function api_qna_comment_edit(qnaData) {
  return api_common("/qna/comment/edit", qnaData,true);
}
function api_qna_comment_delete(qnaData) {
  return api_common("/qna/comment/delete", qnaData, true);
}

////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSE
//
////////////////////////////////////////////////////////////////////////////////////
function handleResponse(response) {
  // return response.json().then((json) => {
  //     return json;
  // });

  return response.json().then((json) => {
    if (json.code === "0000" || json.code === "0001") {
      return json;
    } else {
      const error = json || response.statusText;
      return Promise.reject(error);
    }
  });
}
