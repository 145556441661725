import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { LANGUAGE_FOR_OS } from "../types";

import localeKO from "./lang/lang_ko.json";
import localeEN from "./lang/lang_en.json";

const resource = {
  [LANGUAGE_FOR_OS.EN]: { translation: localeEN },
  [LANGUAGE_FOR_OS.KO]: { translation: localeKO },
  [LANGUAGE_FOR_OS.KO_KR]: { translation: localeKO },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resource,
    fallbackLng: "en-US",
    debug: true,
    interpolation: {
      escapeValue: true,
      prefix: "{",
      suffix: "}",
    },
  });

export default i18n;
