import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle } from "react-feather";
import classnames from "classnames";
import MyThingsIcon from "../../../../assets/images/logo/ci_default.png";
import {projectContext} from "../../../../utility/context/projectSelect";
import {ApiService} from "../../../../service/service.rest";
import styled from "styled-components";

const NavBarHeaderCi = styled.div`
  background: url(${props => props.src}) no-repeat;
  background-size: contain;
  width: 190px;
  height: 50px;
`;

class SidebarHeader extends Component {
  state = {
    userGroupCiUrl: "",
    user_group_idx: 0,
  };

  static contextType = projectContext;

  componentDidUpdate(prevProps, prevState) {
    if (this.context.state.user_info.user_group_idx !== this.state.user_group_idx) {
      this.setState({
        user_group_idx: this.context.state.user_info.user_group_idx,
      }, () => {
        this.callUserGroupCi();
      });
    }
  };

  callUserGroupCi = () => {
    if (this.state.user_group_idx !== undefined && this.state.user_group_idx !== 0) {
      ApiService.api_user_group_detail(this.state.user_group_idx).then(
        (response) => {
          if (response.code === "0000") {
            this.setState({
              userGroupCiUrl: response.data.ci_image_url,
            })
          }
        },
        (error) => {
          if (error.code === "1002") {
            this.setState({
              userGroupCiUrl: "",
            });
        }}
      );
    }
  };

  render() {
    let { toggleSidebarMenu, activeTheme, collapsed, toggle, sidebarVisibility, menuShadow, appTheme } = this.props;
    return (
      <div
        className="navbar-header"
      >
        <ul
          className="nav navbar-nav flex-row align-items-center"
          style={{
            backgroundColor: appTheme === "light" && activeTheme === "primary" ? "#42b4f4"
              : appTheme === "light" && activeTheme === "success" ? "#5cb85c"
                : appTheme === "light" && activeTheme === "danger" ? "#f55a4e"
                  : appTheme === "light" && activeTheme === "info" ? "#03a9f3"
                    : appTheme === "light" && activeTheme === "warning" ? "#ff9700"
                      : appTheme === "light" && activeTheme === "dark" ? "#343a40" : "#10163a",
          }}
        >
          <li className="nav-item mr-auto">
            <NavLink to="/" className="navbar-brand">
              <NavBarHeaderCi src={this.state.userGroupCiUrl === "" || this.state.userGroupCiUrl === null ? MyThingsIcon : this.state.userGroupCiUrl} theme={appTheme} />
            </NavLink>
          </li>
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {JSON.parse(window.localStorage.getItem("projectMenuCollapsed")) === false ? (
                <Disc
                  onClick={() => {
                    toggleSidebarMenu(true);
                    toggle();
                  }}
                  className={classnames("toggle-icon icon-x d-none d-xl-block font-medium-4", {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  })}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                <Circle
                  onClick={() => {
                    toggleSidebarMenu(false);
                    toggle();
                  }}
                  className={classnames("toggle-icon icon-x d-none d-xl-block font-medium-4", {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  })}
                  size={20}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames("toggle-icon icon-x d-block d-xl-none font-medium-4", {
                  "text-primary": activeTheme === "primary",
                  "text-success": activeTheme === "success",
                  "text-danger": activeTheme === "danger",
                  "text-info": activeTheme === "info",
                  "text-warning": activeTheme === "warning",
                  "text-dark": activeTheme === "dark",
                })}
                size={20}
              />
            </div>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

export default SidebarHeader;
